import styled, { css } from "styled-components";

const buttonLargeStyles = css`
  width: 30%;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  min-width: 200px;
  font-weight: 700;
`

const buttonMediumStyles = css`
  padding: 7px 16px;
  font-size: 13px;
  line-height: 24px;
`

const buttonPrimaryStyles = css`
  background: transparent;
  border: 1px solid #E7DDA0;

  &:not(:disabled):hover {
    background: #E7DDA0;
    border-color:  #E7DDA0;
    color: #2C2C2C
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    background: #E7DDA0;
    border-color: #E7DDA0;
    color: #2C2C2C
  }
`

const buttonSecondaryStyles = css`
  background: #7BE5C4;
  color: #373737;
  height: 30px;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #7BE5C4;


  &:not(:disabled):hover {
    border: 1px solid #E7DDA0;
    background: #E7DDA0;
  }
  &:not(:disabled):active,
  &:not(:disabled):focus {
    border: 1px solid #E7DDA0;
    box-sizing: border-box;
    border-radius: 8px;
    background: #373737;
    color: #ffffff;
  }
`

const BaseButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  color: #E7DDA0;
  border-radius: 8px;
  cursor: pointer;
  outline: none;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const ButtonPrimaryLarge = styled(BaseButton)`
  ${buttonPrimaryStyles}
  ${buttonLargeStyles}
`;

export const ButtonPrimaryMedium = styled(BaseButton)`
  ${buttonPrimaryStyles}
  ${buttonMediumStyles}
`;

export const ButtonSecondaryLarge = styled(BaseButton)`
  ${buttonSecondaryStyles}
  ${buttonLargeStyles}
`;

export const ButtonSecondaryMedium = styled(BaseButton)`
  ${buttonSecondaryStyles}
  ${buttonMediumStyles}
`;
