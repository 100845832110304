import React, { useRef, useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import styled from "styled-components";
import logo from "./../../assets/1x/0 TOP Banner/Asset 3.png";
import menuIcon from "./../../assets/images/icons/hamburger.svg";
import closeIcon from './../../assets/images/icons/cross-grey.svg'
import { useToggler } from "../../hooks/useToggler";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Connection } from "../Connection";
import Ticker from 'react-ticker'

import twitter from "./../../assets/1x/0 TOP Banner/Asset 6.png";
import insta from "./../../assets/1x/0 TOP Banner/Asset 5.png";
import discord from "./../../assets/1x/0 TOP Banner/Asset 7.png";
import opensea from "./../../assets/1x/0 TOP Banner/Asset 8.png";

import stakingOff from "./../../assets/images/Nav_Staking_Off.png";
import stakingOn from "./../../assets/images/Nav_Staking_On.png";

import raffleOff from "./../../assets/images/Nav_Raffles_Off.png";
import raffleOn from "./../../assets/images/Nav_Raffles_On.png";

import boostOff from "./../../assets/images/Nav_Boosts_Off.png";
import boostOn from "./../../assets/images/Nav_Boosts_On.png";



const GetRatesFromAPI = () => {
  const [rates, setRates] = useState("");
  useEffect(() => {
    // Update the count down every 1 second
    var x = setInterval(function() {
        var countDownDate = new Date(1651942860000).getTime();
        // Get todays date and time
        var now = new Date().getTime();

        // Find the distance between now an the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // If the count down is over, write some text
        if (distance < 0) {
            setRates('LIVE NOW')
        } else {
          // Output the result in an element with id="demo"
          setRates(hours + "h " + minutes + "m " + seconds + "s ")
        }
    }, 1000);


  }, []);
  // A placeholder is needed, to tell react-ticker, that width and height might have changed
  // It uses MutationObserver internally
    return (<DefaultText style={{ whiteSpace: "nowrap" }}>Whitelist free mint: SOLD OUT</DefaultText>)
};

export const Header = () => {
  const { visible, toggle } = useToggler()
  const ref = useRef(null)
  useClickOutside(ref, () => toggle(false))

  const [selectedLink, setSelectedLink] = React.useState('')
  const setActiveClassName = (name: string) => name == selectedLink ? 'active' : ''

  return (
    <>
    <Ticker offset="run-in" speed={8} mode={'smooth'}>
      {() => <GetRatesFromAPI />}
    </Ticker>
    <HeaderRow>
      <HeaderSection>
        <LogoLink style={{color: 'gray'}} onClick={() => setSelectedLink('')}  to="/">
          <img src={logo} alt="logo" style={{width: '30px', height: 'auto'}} />
        </LogoLink>
      </HeaderSection>
      <MenuOpenButton onClick={() => toggle(true)}>
        <img src={menuIcon} alt="Menu"/>
      </MenuOpenButton>
      <Menu ref={ref} className={visible ? 'visible' : ''}>
        <MenuHeader>
          <img src={logo} alt="logo" style={{width: '30px', height: 'auto'}} />
        </MenuHeader>
        <MenuContent>
          <nav style={{height: '100%'}}>
            <NavList>
              <NavItem>
                <Link onClick={() => setSelectedLink('about')} className={setActiveClassName('about')} to="/#about">
                About
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={() => setSelectedLink('team')} className={setActiveClassName('team')}  to="/#team" >
                Team
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={() => setSelectedLink('roadmap')} className={setActiveClassName('roadmap')} to="/#roadmap">
                Roadmap
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={() => setSelectedLink('faq')} className={setActiveClassName('faq')} to="/#faq" >
                FAQs
                </Link>
                <Link onClick={() => setSelectedLink('mint')} className={setActiveClassName('mint')} to="/mint" >
                Mint
                </Link>
              </NavItem>
            </NavList>

          </nav>


          <RightSideDiv>
          <SocialButton onClick={() => window.open('https://opensea.io/collection/tyco-swagg-unchained', "_blank")}>
            <SocialImage src={opensea}/>
          </SocialButton>
          <SocialButton onClick={() => window.open('https://www.instagram.com/tycoswagg', "_blank")}>
            <SocialImage src={insta}/>
          </SocialButton>
          <SocialButton onClick={() => window.open('https://twitter.com/tycoswagg', "_blank")}>
            <SocialImage src={twitter}/>
          </SocialButton>
          <SocialButton onClick={() => window.open('https://discord.gg/c7Mf9ehc8T', "_blank")}>
            <SocialImage src={discord}/>
          </SocialButton>
          <ConnectionWrapper>

            <Connection/>
          </ConnectionWrapper>
          </RightSideDiv>

        </MenuContent>

      </Menu>
    </HeaderRow>
    </>
  )
}

export const DefaultText = styled.p`
  color: rgba(43,22,21,1);
  font-weight: 500;
  font-size: 15px;
`;

export const SocialButton = styled.button`
padding-left: 10px;
padding-right: 10px;
margin-bottom: auto;
margin-top: auto;
@media (max-width: 790px) {
  flex-direction: column;
  padding: 10px;
}

`;
export const SocialImage = styled.img`
  width:25px;
  height: auto;
`;


const RightSideDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;

@media (max-width: 790px) {
  flex-direction: column;
}

`;

const HeaderRow = styled.header`
  height: 130px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px;
  background: transparent;
  z-index: 5;

  @media (max-width: 900px) {
    justify-content: space-between;
  }
  @media (max-width: 790px) {
    padding: 8px 16px;
  }
`;

const HeaderSection = styled.div`
  width: 130px;

  @media (max-width: 900px) {
    width: initial;
  }
`;

const HeaderImage = styled.img`
  height: 60px;
  width: auto;
`;

const HeaderImageHover = styled.img`
  height: 60px;
  width: auto;
  display: none;
`;

const Preview = styled.div`
font-family: 'Mute Logo Font';
text-transform: uppercase;
font-size: 14px;
line-height: 13px;
text-transform: uppercase;
color: #FFFFFF;
`;

const Menu = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 900px) {
    margin-left: 24px;
  }
  @media (max-width: 790px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 224px;
    height: 100%;
    margin: 0;
    background: rgba(255,225,51,1);
    z-index: 99999;
  }
  &.visible {
    border: 0px white solid;
    border-width-right: 3px;
    border-right-width: 3px;

    display: flex;
    flex-direction: column;
  }
`;

const MenuHeader = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 100%;

  @media (max-width: 790px) {
    height: 129px;
    display: flex;
  }
`;

const MenuContent = styled.div`
  justify-content: space-between;
  height: 100%;
  padding-left: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 790px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 48px 32px 32px;
  }
`;


const MenuOpenButton = styled.button`
  display: none;
  margin-right: 8px;
  padding: 0;

  @media (max-width: 790px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MenuCloseButton = styled.button`
  margin-left: auto;
`;


const NavList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 790px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Link = styled(HashLink)`
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  line-height: 13px;
  text-transform: uppercase;
  color: rgba(43,22,21,1);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  &:hover ${HeaderImageHover} {
    display: inline;
  }

  &:hover ${HeaderImage} {
      display: none;
  }

  &.disabled {
    color: rgba(238,129, 61, 1);
  }

  &:hover {
    color: rgba(238,129, 61, 1);
  }

  @media (max-width: 790px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;

const LogoLink = styled(NavLink)`
  font-family: 'Poppins';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 790px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;


const NavItem = styled.li`
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  display: flex;

  padding-left: 10px;
  padding-rigth: 10px;


  & + & {
    @media (max-width: 790px) {
      margin: 56px 0 0;
    }
  }

  @media (max-width: 790px) {
    height: auto;
    justify-content: flex-start;
    width: 100%;
  }

  `;

const ConnectionWrapper = styled.div`
  width: auto;
`;
