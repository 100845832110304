import React, { ReactNode } from "react";
import { BrowserRouter } from "react-router-dom";
import { ConnectionProvider } from "../pages/Connect/ConnectionProvider";

interface Props {
  children: ReactNode;
}
export function Providers({ children }: Props) {
  return (
    <ConnectionProvider>
      <BrowserRouter>
        {children}
      </BrowserRouter>
    </ConnectionProvider>
  );
}
