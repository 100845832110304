import React, { useRef, useState } from 'react'
import styled from 'styled-components';
import { reduceString } from '../utils/reduceString';
import angleIcon from '../assets/images/icons/angle-light.svg'
import { useToggler } from '../hooks/useToggler';
import { useClickOutside } from '../hooks/useClickOutside';
import { ConnectModal } from './ConnectModal';
import { ButtonPrimaryMedium } from './buttons/Button';
import { useConnection } from '../pages/Connect/ConnectionProvider';
import { ModalButton } from './modal/ModalComponents';
import  Wallet  from '../web3/index'
import makeBlockie from 'ethereum-blockies-base64';
import {SymbolWithLogo} from './SymbolWithLogo';

enum Modal {
  Connect,
  Switch
}

export const Connection = () => {
  const {connection, layerStatus, noise} = useConnection();
  const [isConnected, setIsConnected] = connection;

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null)

  const disconnect = () => {
    Wallet.disconnect()
    Wallet.state.initiated = true
    setIsConnected(false)
  }

  const connect = () => {
    setIsConnected(true)
  }

  const onWalletConnect = async (prov: any) => {
    try{
      if(Wallet.state.account == '0x000000000000000000000000000000000'){
        var res = await Wallet.onWeb3Connect(prov)
        Wallet.state.initiated = res
        setIsConnected(res)
        setModal(null)
      }

    } catch(e) {
    }
  }

  React.useEffect(() => {

    /*
    if(Wallet.state.initiated == false)
      onWalletConnect('injected');
      */

    return () => {}
  }, []);



  if (isConnected) {
    return (
      <ConnectMenu>
        <ConnectionDropdown
          account={Wallet.state.account}
          disconnect={disconnect}
        />
      </ConnectMenu>
    )
  }

  return (
    <>
      <ConnectButton onClick={() => setModal(Modal.Connect)}>Connect</ConnectButton>
      <ConnectModal
        isVisible={modal === Modal.Connect}
        onClose={closeModal}
        onConnectCallback={connect}
      />
    </>
  )
}

interface ConnectionDropdownProps {
  account: string;
  disconnect: () => void;
}

export const ConnectionDropdown = ({account, disconnect}: ConnectionDropdownProps) => {
  const { visible, toggle } = useToggler()
  const ref = useRef(null)

  return (
    <DropdownMaster>

    <Dropdown ref={ref} visible={visible} onClick={() => toggle()}>
      <img src={makeBlockie(account)} style={{width: '20px', height: '20px', borderRadius: '10px'}}/>
      <AccountText>{reduceString(account, 7, 4)}</AccountText>
    </Dropdown>
    {visible && <DisconnectButton onClick={disconnect}>Disconnect</DisconnectButton>}

    </DropdownMaster>
  )
}


const ConnectMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: 790px) {
    flex-direction: column;
  }


`;

const ConnectMenuBalance = styled(ConnectMenu)`
  padding-right: 20px;

  @media (max-width: 790px) {
    flex-direction: row;
  }


`;

const BasicText = styled.p`
  font-weight: 300;
`;

const BalanceText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  color: #E7DDA0;
  font-size: 13px;
  font-weight: 700;
  justify-content: center;
  align-items: flex-start;

  @media (max-width: 790px) {
    flex-direction: column;
  }
`;

const BIcon = styled.img`
  width: 60px;
  height: auto;
`;

const ConnectButton = styled(ButtonPrimaryMedium)`
  margin-left: auto;
  width: 128px;
  background-color: rgba(43,22,21,1);
  font-weight: 700;
`;

const Dropdown = styled.button<DropdownButtonProps>`
  position: relative;
  display: flex;
  background-color: rgba(43,22,21,1);
  padding: 0px 14px 0px 14px;
  border-radius: 8px;
  width: 180px;
  align-items: right;
  justify-content: space-around;
  text-align: right;
  align-items: center;

  &:hover {
    outline: none;
    bord;
  }
`;

const AccountText = styled.button`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: rgba(250,224,82,1);
  font-weight: 700;

  &:focus {
    outline: none;
  }
`;


const DropdownMaster = styled.div`
  position: relative;
  display: flex;
  width: auto;
  align-items: right;
  justify-content: space-around;
  text-align: right;
  align-items: center;
`;

interface DropdownButtonProps {
  visible: boolean;
}

const DropdownButton = styled.button<DropdownButtonProps>`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;

  &:focus {
    outline: none;
  }
`;

const DisconnectButton = styled.button`
  position: absolute;
  right: 0;
  bottom: -3px;
  transform: translateY(100%);
  max-width:  180px;
  width: 100%;
  padding: 12px 10px;
  text-align: center;
  border: 1px solid #E7DDA0;
  border-radius: 8px;
  background: #373737;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  z-index: 10;


    &:hover {
      outline: none;
      border: 1px solid #E7DDA0;
    }

`;
